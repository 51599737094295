<template>
    <div id="page-lap-kunjungan-pasien">
      <b-container fluid>
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <b-card>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Dari
                    </template>
                    <b-form-datepicker
                      style="width: 100%"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      no-flip
                      locale="id"
                      v-model="data_search.tanggal_mulai"
                    ></b-form-datepicker>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Sampai
                    </template>
                    <b-form-datepicker
                      style="width: 100%"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      no-flip
                      locale="id"
                      v-model="data_search.tanggal_selesai"
                    ></b-form-datepicker>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Kunjungan
                    </template>
                    <b-form-select
                      :options="option_kunjungan"
                      size="sm"
                      v-model="data_search.kunjungan_sakit"
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Poli/Ruangan
                    </template>
                    <b-form-select
                      :options="options_poli"
                      size="sm"
                      v-model="data_search.ms_poli_id"
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Status Bridging
                    </template>
                    <b-form-select
                      :options="option_bridging"
                      size="sm"
                      v-model="data_search.status_sync_bpjs"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="4" xl="4">

                  <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      Jenis Kelamin
                    </template>
                    <b-form-select
                      :options="option_jns_kelamin"
                      size="sm"
                      v-model="data_search.jenis_kelamin"
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      Umur
                    </template>
                    <div style="width:100%;display: flex;">
                      <b-form-input
                         type="number"
                         class="custom-input-text"
                         v-model="data_search.usia_tahun_mulai"
                      ></b-form-input>
                      <div class="custom-box-satuan">
                        <h6 style="margin:0;font-weight: normal;font-size: 12px;">Thn</h6>
                      </div>

                      <b-form-input
                         type="number"
                         class="custom-input-text"
                         v-model="data_search.usia_bulan_mulai"
                      ></b-form-input>
                      <div class="custom-box-satuan">
                        <h6 style="margin:0;font-weight: normal;font-size: 12px;">Bln</h6>
                      </div>

                      <b-form-input
                         type="number"
                         class="custom-input-text"
                         v-model="data_search.usia_hari_mulai"
                      ></b-form-input>
                      <div class="custom-box-satuan">
                        <h6 style="margin:0;font-weight: normal;font-size: 12px;">Hari</h6>
                      </div>
                    </div>
                  </b-form-group>

                  <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      Sampai
                    </template>
                    <div style="width:100%;display: flex;">
                      <b-form-input
                         type="number"
                         class="custom-input-text"
                         v-model="data_search.usia_tahun_selesai"
                      ></b-form-input>
                      <div class="custom-box-satuan">
                        <h6 style="margin:0;font-weight: normal;font-size: 12px;">Thn</h6>
                      </div>

                      <b-form-input
                         type="number"
                         class="custom-input-text"
                         v-model="data_search.usia_bulan_selesai"
                      ></b-form-input>
                      <div class="custom-box-satuan">
                        <h6 style="margin:0;font-weight: normal;font-size: 12px;">Bln</h6>
                      </div>

                      <b-form-input
                         type="number"
                         class="custom-input-text"
                         v-model="data_search.usia_hari_selesai"
                      ></b-form-input>
                      <div class="custom-box-satuan">
                        <h6 style="margin:0;font-weight: normal;font-size: 12px;">Hari</h6>
                      </div>
                    </div>
                  </b-form-group>

                  <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      No. Asuransi
                    </template>
                    <b-form-input
                      type="text"
                      size="sm"
                      v-model="data_search.no_asuransi"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      Status Pulang
                    </template>
                    <b-form-select
                      :options="options_pulang"
                      size="sm"
                      v-model="data_search.ms_status_pulang_id"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                    <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Diagnosa
                    </template>
                    <multiselect
                      :options="options_diagnosa"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_diagnosa"
                      size="sm"
                      v-model="data_search.diagnosa_id"
                    ></multiselect>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Tindakan
                    </template>
                    <multiselect
                      :options="options_tindakan"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_tindakan"
                      size="sm"
                      v-model="data_search.tindakan_id"
                    ></multiselect>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Kecamatan
                    </template>
                    <multiselect
                      :options="options_kecamatan"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_kecamatan"
                      size="sm"
                      v-model="data_search.kecamatan_id"
                      @input="getKelurahan(data_search.kecamatan_id)"
                    ></multiselect>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Kelurahan/Desa
                    </template>
                    <multiselect
                      id=""
                      :options="options_kelurahan"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      size="sm"
                      label="nama_kelurahan"
                      v-model="data_search.kelurahan_id"
                    ></multiselect>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <div style="display:flex;justify-content:flex-start">
                    <b-button @click="getDataKunjungan()" variant="primary" class="mr-2">Tampilkan</b-button>
                    <!-- <b-button variant="success" class="mr-2">Export to Excel</b-button>
                    <b-button variant="warning">Reset</b-button> -->
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <h4 style="text-align:center"><strong>LAPORAN HARIAN - KUNJUNGAN PASIEN BPJS</strong></h4>
                  <h5 style="text-align:center; margin-top:5px"><strong>Total Kunjungan : {{ total }}</strong></h5>
                </b-col>
              </b-row>

              <!-- <b-row style="margin-top:15px;">
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <b-table-simple borderless class="custom-table">
                    <b-tbody>
                      <b-tr>
                        <b-td style="width:140px;">Puskesmas</b-td>
                        <b-td style="width:15px;">:</b-td>
                        <b-td>{{ nama_puskesmas }}</b-td>
                      </b-tr>

                      <b-tr>
                        <b-td>Tanggal</b-td>
                        <b-td>:</b-td>
                        <b-td>{{ tanggalnya }}</b-td>
                      </b-tr>

                      <b-tr>
                        <b-td>Jenis Kelamin</b-td>
                        <b-td>:</b-td>
                        <b-td>{{ jenis_kelamin }}</b-td>
                      </b-tr>

                      
                    </b-tbody>
                  </b-table-simple>
                </b-col>
                <hr/>
                <b-col cols="12" sm="12" md="12" lg="6" xl="6" >
                  <b-table-simple borderless class="custom-table">
                    <b-tbody>
                      <b-tr>
                        <b-td style="width:140px;">Kunjungan</b-td>
                        <b-td style="width:15px">:</b-td>
                        <b-td>{{ kunjungan }}</b-td>
                      </b-tr>

                      <b-tr>
                        <b-td>Asuransi</b-td>
                        <b-td>:</b-td>
                        <b-td>{{ asuransi }}</b-td>
                      </b-tr>

                      <b-tr>
                        <b-td>Total</b-td>
                        <b-td>:</b-td>
                        <b-td>{{ total }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row> -->
              <b-row class="mt-4">
                <b-col md="9" offset-md="3">
                  <b-form-group
                    label="Cari"
                    label-for="filter-input"
                    label-cols-md="3"
                    label-align-md="right"
                    label-size="md"
                  >
                    <b-input-group size="md">
                  
                    <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Ketik disini untuk mencari ..."
                      ></b-form-input>
                    

                      <b-input-group-append>
                        <b-button
                          :disabled="!filter"
                          @click="filter = ''"
                          variant="danger"
                          >Hapus</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <b-table
                    :items="items"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    responsive
                    show-empty
                    small
                    @filtered="onFiltered"
                    bordered
                    striped
                    hover
                    :busy="tableBusy"
                  >
                  <template #cell(diagnosa)="item">
                      <b-row>
                          <b-col>
                              <ol>
                                  <li v-for="(itemnya, index) in item.item.nama_diagnosa" :key="index">{{ itemnya }}</li>
                              </ol>
                          </b-col>
                      </b-row>
                  </template>
                  <template #cell(jenis_kasus)="item">
                      <b-row>
                          <b-col>
                              <ol>
                                  <li v-for="(itemnya, index) in item.item.kasus_diagnosa" :key="index">{{ itemnya }}</li>
                              </ol>
                          </b-col>
                      </b-row>
                  </template>
                  </b-table>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="3">
                  <b-form-group
                    label="Per Halaman"
                    label-for="per-page-select"
                    label-cols-md="6"
                    label-align-md="left"
                    label-size="md"
                    style="background-color: "
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="md"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="6" offset-md="3">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="fill"
                    size="sm"
                  ></b-pagination>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </template>
  
  <script>
  import Multiselect from "vue-multiselect";
  export default {
    name: "kunjungan_pasien",
    components: {
      Multiselect
    },
    data() {
      return {
        showing: false,
        variant: "success",
        msg: "",
        value: null,
        data_search: {
          tanggal_mulai: null,
          tanggal_selesai: null,
          kunjungan_sakit: null,
          jenis_kelamin: null,
          usia_tahun_mulai: null,
          usia_bulan_mulai: null,
          usia_hari_mulai: null,
          usia_tahun_selesai: null,
          usia_bulan_selesai: null,
          usia_hari_selesai: null,
          diagnosa_id: null,
          tindakan_id: null,
          status_sync_bpjs: null,
          ms_status_pulang_id: null,
          ms_poli_id: null,
          no_asuransi: null,
          kecamatan_id: null,
          kelurahan_id: null,
          ms_diagnosa_id: null,
          ms_tindakan_id: null,
          ms_kecamatan_id: null,
          ms_kelurahan_id: null,
          halaman: 0,
          jumlah: 1000,
        },
        nama_puskesmas: null,
        tanggalnya: null,
        kunjungan: null,
        asuransi: null,
        total: null,
        jenis_kelamin: null,
        options_kecamatan: [],
        options_kelurahan: [],
        options_diagnosa: [],
        options_tindakan: [],
        options_poli: [],
        option_kunjungan: [
          {value: null, text: "Semua"},
          {value: "true", text: "Sakit"},
          {value: "false", text: "Sehat"},
        ],

        option_bridging: [
          {value: null, text: "Semua"},
          {value: 0, text: "Belum"},
          {value: 1, text: "Sudah"},
        ],

        option_jns_kelamin: [
          {value: null, text: "Semua"},
          {value: "L", text: "Laki-laki"},
          {value: "P", text: "Perempuan"},
        ],

        options_pulang: [],

        // option_wilayah: [
        //   {value: "", text: "Semua"},
        //   {value: "", text: "Dalam Wilayah Puskesmas"},
        //   {value: "", text: "Dalam Kota/Kab"},
        //   {value: "", text: "Luas Kota"},
        //   {value: "", text: "Luar Wilayah Puskesmas Dan Dalam Kota/Kab"},
        //   {value: "", text: "Luar Wilayah Puskesmasm Dalam Dan Dalam Luar Kota/Kab"},
        // ],
        fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },

        {
          key: "tgl",
          label: "Tanggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_lengkap",
          label: "Nama Pasien",
          sortable: true,
          sortDirection: "desc",
          class: "custom-w-nama text-left",
        },
        {
          key: "no_rm",
          label: "No. eRM",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nik",
          label: "NIK",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "noDokRm",
        //   label: "No. Dokumen RM",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },

        {
          key: "jenis_kelamin",
          label: "Jenis Kelamin",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },

        {
          key: "ttl",
          label: "Tempat & Tanggal Lahir",
          sortable: true,
          sortDirection: "desc",
          class: "custom-w-ttl text-left",
        },

        {
          key: "umur",
          label: "Umur",
          sortable: true,
          sortDirection: "desc",
          class: "custom-w-umur text-left",
        },

        // {
        //   key: "pekerjaan",
        //   label: "Pekerjaan",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },

        {
          key: "alamat_sekarang",
          label: "Alamat",
          sortable: true,
          sortDirection: "desc",
          class: "custom-w-alamat text-left",
        },

        {
          key: "nama_kelurahan",
          label: "Kelurahan",
          sortable: true,
          sortDirection: "desc",
          class: "custom-w-umur text-left",
        },

        // {
        //   key: "agama",
        //   label: "Agama",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },

        // {
        //   key: "status_perkawinan",
        //   label: "Status Pernikahan",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },

        

        {
          key: "nama_ayah",
          label: "Nama Ayah",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        // {
        //   key: "kunjungan_sakit",
        //   label: "Jenis Kunjungan",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },

        {
          key: "kunjungan",
          label: "Kunjungan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "nama_poli",
          label: "Poli / Ruangan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "asuransi",
          label: "Asuransi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "no_kartu",
          label: "No. Asuransi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "diagnosa",
          label: "Diagnosa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        // {
        //   key: "jenis_kasus",
        //   label: "Jenis Kasus",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      // filterOn: [],
      tableBusy: false,
      };
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => {
            return { text: f.label, value: f.key };
          });
      },
      filterOn() {
        // console.log(this.fields.map(el => el.key))
        return this.fields.map(el => el.key)
      },
    },
    activated(){
      this.totalRows = this.items.length;
      this.data_search.tanggal_mulai = this.$moment(new Date()).format("YYYY-MM-DD")
      this.data_search.tanggal_selesai = this.$moment(new Date()).format("YYYY-MM-DD")
      this.getDataKunjungan();
      this.getData()
      this.getKecamatan()
    },
    // async mounted() {
    //   // Set the initial number of items
    //   this.totalRows = this.items.length;
    //   this.getDatas();
    // },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async getData(){
        let vm = this
        let status_pulang = await vm.$axios.post("/ms_status_pulang/list")
        if(status_pulang.data.data.length){
            console.log(status_pulang);
            vm.options_pulang = [{value:null, text: "Semua"}]
            for (let i = 0; i < status_pulang.data.data.length; i++) {
                let x = status_pulang.data.data[i];
                let data = {}
                data.value = x.ms_status_pulang_id
                data.text = x.nm_status_pulang
                vm.options_pulang.push(data)
            }
        }

        let tindakan = await vm.$axios.post("/ms_tindakan/list")
        if(tindakan.data.data.length){
            vm.options_tindakan = tindakan.data.data
        }

        let diagnosa = await vm.$axios.post("/ms_diagnosa/list")
        if(diagnosa.data.data.length){
            vm.options_diagnosa = diagnosa.data.data
            for (let i = 0; i < vm.options_diagnosa.length; i++) {
                let x = vm.options_diagnosa[i];
                x.nama_diagnosa = `${x.kd_diag} - ${x.nm_diag}`
            }
        }

        let poli = await vm.$axios.post("/ms_poli/list")
        if(poli.data.data.length){
            vm.options_poli = [{value:null, text: "Semua"}]
            for (let i = 0; i < poli.data.data.length; i++) {
                let x = poli.data.data[i];
                let data = {}
                data.value = x.ms_poli_id
                data.text = x.nama_poli
                vm.options_poli.push(data)
            }
        }
      },
      async getKecamatan(){
        let vm = this
        let kecamatan = await vm.$axios.post("/ms_kecamatan/list")
        vm.options_kecamatan = kecamatan.data.data
      },
      async getKelurahan(x){
        let vm = this
        vm.data_search.kelurahan_id = null
        let kelurahan = await vm.$axios.post("/ms_kelurahan/list", {ms_kecamatan_id: x.ms_kecamatan_id})
        vm.options_kelurahan = kelurahan.data.data
      },
      getDataKunjungan(){
        let vm = this
        if(vm.data_search.kecamatan_id){
          vm.data_search.ms_kecamatan_id = vm.data_search.kecamatan_id.ms_kecamatan_id
        }
        if(vm.data_search.kelurahan_id){
          vm.data_search.ms_kelurahan_id = vm.data_search.kelurahan_id.ms_kelurahan_id
        }
        if(vm.data_search.diagnosa_id){
          vm.data_search.ms_diagnosa_id = vm.data_search.diagnosa_id.ms_diagnosa_id
        }
        if(vm.data_search.tindakan_id){
          vm.data_search.ms_tindakan_id = vm.data_search.tindakan_id.ms_tindakan_id
        }
        vm.$axios.post("/kunjungan/list_laporan_kunjungan_bpjs", vm.data_search)
        .then((res) => {
          console.log(res, 'ini respon');
          vm.items = res.data.data
          vm.total = res.data.data.length
          for (let i = 0; i < vm.items.length; i++) {
            let x = vm.items[i];
            x.no = i+1
            x.tgl = vm.$moment(x.tanggal_daftar).format("DD-MM-YYYY HH:mm")
            x.ttl = `${x.tempat_lahir}, ${vm.$moment(x.tanggal_lahir).format("DD-MM-YYYY")}`
            x.umur = `${x.usia_tahun} tahun ${x.usia_bulan} bulan ${x.usia_hari} hari`
            if(x.kunjungan_sakit == true){
              x.kunjungan = "Sakit" 
            }else {
              x.kunjungan = "Sehat"
            }
          }
          vm.totalRows = vm.items.length
        })
        .catch((err) => {
          console.log(err);
        })
      },
      
      triggerAlert(event) {
        let vm = this;
        vm.$store.commit("set_alert", event);
      },
    },
  };
  </script>
  <style>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  #page-lap-kunjungan-pasien .custom-table td{
    padding:0 !important;
    font-size:14px;
    font-weight:bold;
    border:hidden !important;
  }

  
  </style>
  